<template>
  <section class="mapWrapper container">
    <div class="mapInfo">
      <ul>
        <li>
          <p class="title">{{ propsData.name }}</p>
          <p v-html="propsData.description" class="mapDesc"></p>
        </li>
        <li v-if="phoneNumbers">
          <p class="title">{{ propsData.our_contacts }}</p>
          <a class="contactItem" :href="`mailto:${propsData.email}`">{{
            propsData.email
          }}</a>
          <a
            class="contactItem"
            :href="`tel:${item.number}`"
            v-for="(item, idx) in phoneNumbers"
            :key="idx"
          >
            {{ item.label }}
          </a>
        </li>
      </ul>
    </div>
    <GmapMap class="map" :center="coords" :zoom="17" style="height: 600px">
        <GmapMarker
        :key="index"
        v-for="(m, index) in markers"
        :position="m.position"
        :clickable="true"
        :draggable="true"
        @click="center=m.position"
        />
    </GmapMap>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    propsData: {
      type: [Array, Object]
    }
  },
  data() {
    return {
        markers: [
            {
                position: {
                    lat:  +this.propsData.coords_lat,
                    lng:  +this.propsData.coords_lng,
                }
            }
        ],
    };
  },
  computed: {
    ...mapGetters(["globalSetting", "getSize"]),
    phoneNumbers() {
      const temp = [];
      temp.push(
        {
          number: this.propsData.phone,
          label: this.propsData.phone_name
        },
        {
          number: this.propsData.phone2,
          label: this.propsData.phone_name2
        },
        {
          number: this.propsData.phone3,
          label: this.propsData.phone_name3
        }
      );
      return temp;
    },
    coords() {
      const temp = {};
      // temp.lat = +this.globalSetting.maps_coord_lat
      // temp.lng = +this.globalSetting.maps_coord_lng
      temp.lat = +this.propsData.coords_lat;
      temp.lng = +this.propsData.coords_lng;
      return temp;
    }
  }
};
</script>

<style lang="sass" scoped>
.mapWrapper
    position: relative
    height: 600px
    // margin: $section-offset auto
    margin-bottom: 70px
    @media (max-width: 1024px)
        height: 512px
        padding: 0px !important
    // @media (max-width: 830px)
    //     margin: 100px auto
    @media (max-width: 576px)
        height: auto
        display: flex
        flex-direction: column
        // padding: 0px 20px !important
.mapInfo
    position: absolute
    left: 0px
    top: 0px
    max-width: 450px
    width: 100%
    background-color: rgba(#000, .8)
    padding: 50px
    z-index: 2
    height: inherit
    display: flex
    justify-content: center
    flex-direction: column
    @media (max-width: 1024px)
        padding: 46px
        height: inherit
        max-width: 358px
    @media (max-width: 830px)
        padding: 25px
    @media (max-width: 576px)
        position: static
        max-width: unset
        text-align: center
        background-color: rgba(#000, .8)
    .title
        font-weight: 500
        margin-bottom: 15px
        font-size: 25px
        line-height: 140%
        color: #FFFFFF
    @media (max-width: 830px)
        font-size: 16px
        line-height: 140%
    ul
        margin: 0px
    li
        &:not(:last-of-type)
            margin-bottom: 78px
            @media (max-width: 1024px)
                margin-bottom: 60px
            @media (max-width: 830px)
                margin-bottom: 51px
        p, .contactItem
            font-weight: 300
            font-size: 14px
            line-height: 140%
            color: #FFFFFF
            &:not(:last-of-type)
                margin-bottom: 10px
        .contactItem
            display: block
.map
    width: 100%
    height: 100%
    top: 0px
    left: 0px
    z-index: 1
    @media (max-width: 1024px)
        height: 512px !important
    @media (max-width: 576px)
        // position: static
        // height: auto !important
</style>
