<template>
  <div class="contentWrapper" :class="{ container: this.$route.name === 'TextPage' }">
    <!-- <h2 v-if="propsData.title">{{ propsData.title }}</h2> -->
    <div
      ref="textWrapper"
      class="contentWrapper"
      v-html="propsData.text"
      v-if="!isUiLocked"
    ></div>
    <div class="author" v-html="propsData.title"></div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Quote",
  props: {
    propsData: {
      type: [Object, Array],
      require: true
    }
  },
  computed: {
    ...mapGetters(["isUiLocked", "globalSetting"])
  }
};
</script>

<style lang="sass" scoped>
.contentWrapper
  font-style: italic
  font-weight: 300
  font-size: 17px
  line-height: 150%
  color: $c-white
//   margin-bottom: 35px
.author
  font-style: normal
  font-weight: 600
  font-size: 17px
  line-height: 150%
  color: $c-btn

  padding-left: 30px
  margin-top: 20px
  position: relative
  &:before
    content: ""
    position: absolute
    top: 11px
    left: 2px
    width: 17px
    height: 2px
    background-color: $c-btn
</style>
