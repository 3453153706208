<template>
  <div
    :class="{
      textPage:
        $route.params.textPageSlug !== 'contacts' &&
        $route.params.textPageSlug !== 'licenzii'
    }"
  >
    <section
      :class="$route.params.textPageSlug"
      class="mb"
      v-if="!isUiLocked"
      ref="contentZone"
    >
      <div class="textContainer">
        <the-main-text
          :title="textPageData.translate.title"
          :descr="textPageData.translate.description"
        ></the-main-text>
      </div>
      <app-seo-text
        v-if="$route.params.textPageSlug == 'licenzii'"
        :propsData="textPageData.model.seo_text"
      ></app-seo-text>
      <component
        v-for="(item, idx) in constructorList"
        :is="item.component"
        :key="idx"
        :propsData="item.content"
      >
      </component>
      <template v-if="pageCheck">
        <location-map
          v-for="(item, idx) in globalSetting.offices"
          :key="`${idx}_map`"
          :propsData="item"
        ></location-map>
      </template>
      <component
        v-for="(wItem, idx) in widgetList"
        :is="wItem.component"
        :key="`${idx}w`"
        :propsData="wItem.content"
      >
      </component>
    </section>
    <app-news v-if="false"></app-news>
    <div class="container-full" v-if="!pageCheck">
      <app-subscribe-form></app-subscribe-form>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import AppNews from "../components/app-news.vue";
import TheMainText from "../components/common/the-main-text.vue";
import TheMap from "../components/contacts/the-map.vue";
import appSimpleText from "../components/text-components/app-simple-text.vue";
import AppTextAndImage from "../components/text-components/app-text-and-image.vue";
import accordionMixin from "@/textAccordion";
import AppQuote from "../components/text-components/app-quote.vue";
import RequestForm from "../components/common/RequestForm.vue";
import AppBlock from "../components/home/app-blocks.vue";
import AppSubscribeForm from "../components/common/app-subscribe-form.vue";
import AppImgPreview from "../components/common/app-imgPreview-t.vue";
import AppSeoText from "../components/common/app-seoText.vue";
export default {
  components: {
    "simple-text": appSimpleText,
    "image-and-text": AppTextAndImage,
    news: AppNews,
    blocks: AppBlock,
    TheMainText,
    "location-map": TheMap,
    quotes: AppQuote,
    form1: RequestForm,
    AppSubscribeForm,
    gallery: AppImgPreview,
    AppSeoText
  },
  mixins: [accordionMixin],
  data() {
    return {
      textPageData: null,
      constructorList: null,
      widgetList: null
    };
  },
  methods: {
    ...mapActions(["lockUi", "unlockUi"])
  },
  computed: {
    ...mapGetters(["isUiLocked", "globalSetting"]),
    pageCheck() {
      return this.$route.fullPath.includes('/contacts');
    },
    mapAppirience() {
      return (
        this.$route.name == "TextPage" &&
        this.$route.params.textPageSlug == "contacts"
      );
    }
  },
  async created() {
    try {
      this.lockUi();
      const res = await this.axios.post("/api/page/get-by-slug", {
        lang: this.currentLang,
        slug: this.$route.params.textPageSlug
      });
      this.textPageData = res.data.data;
      this.constructorList = res.data.data.constructor;
      this.widgetList = res.data.data.widgets;
      document.title =
        res.data.data.translate.meta_title ||
        res.data.data.translate.title ||
        "Dream Travel";
      this.unlockUi();
    } catch (err) {
      if (err.response.status == "404") {
        this.$router.push({
          name: "NotFound",
          params: {
            locale: this.$i18n.locale == "en" ? null : this.$i18n.locale
          }
        });
      }
    }
  },
  mounted() {
    setTimeout(() => {
      this.initToggleSlideBtn(this.$refs.contentZone, false); // from mixin
    }, 600);
  }
};
</script>

<style lang="sass" scoped>
.uiElements
    display: flex
    justify-content: space-between
    align-items: center
    margin-bottom: 0px
    .date
        font-weight: 400
        font-size: 12px
        line-height: 100%
        color: #777777
    ul
        display: flex
        li
            &:first-of-type
                margin-right: 20px !important
                a
                    width: 107px
            &:not(:last-of-type)
                margin-right: 10px
            a
                border: 1px solid $c-btn
                height: 41px
                min-width: 41px
                display: flex
                align-items: center
                justify-content: center
                color: $c-btn
.contacts.mb
    margin-bottom: 0px
.mb
    // margin-bottom: $section-offset
    @media (max-width: 830px)
        margin-bottom: 100px
.textContainer
    @media (max-width: 576px)
        padding-left: 25px
        padding-right: 25px
</style>
<style lang="sass">
.contacts
    .specContainer
        padding-bottom: 0px
</style>
