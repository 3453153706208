<template>
  <div
    class="wrp contentWrapper"
    :class="{
      reverse: propsData.image_position == 'left',
      container: this.$route.name === 'TextPage'
    }"
  >
    <div class="text">
      <div v-html="propsData.description"></div>
      <router-link class="btn" :to="propsData.button_link">{{
        propsData.button_title
      }}</router-link>
    </div>
    <div class="imgWrp">
        <img class="image" :src="path(propsData.image)" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    propsData: {
      type: [Array, Object]
    }
  }
};
</script>

<style lang="sass" scoped>
.wrp
    display: flex
    justify-content: space-between
    margin: 35px auto
    @media (max-width: 576px)
        margin: 0px
    &.reverse
        flex-direction: row-reverse
    @media (max-width: 830px)
        flex-direction: column-reverse !important
    .text
        width: calc(45% - 17px)
        @media (max-width: 830px)
            width: 100%
    .imgWrp
        width: calc(55% - 17px)
        @media (max-width: 830px)
            width: 100%
        .image
            object-fit: contain
            width: 100%
            position: sticky
            top: 120px
</style>
<style lang="sass">
.wrp
    .text div
        & > p, & > span
            font-style: normal !important
            font-weight: 300 !important
            font-size: 17px !important
            line-height: 150% !important
            color: #CACACA !important
        p
            &:first-of-type
                margin-top: 29px
            &:last-of-type
                margin-bottom: 29px
</style>
